




















































































































































































































































































































































































































































































































































































































































































































































































.wrapper_collapse_for_header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  .print_group_wrapper {
    .open_print_group {
      min-width: 80px;
      padding: 8px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffa534;
      border: 1px solid #ffa534;
      color: #fff;
      border-radius: 8px;
      transition: 0.3s;
    }
  }
}
.dialog_footers {
  display: flex;
  justify-content: center;
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
::v-deep .el-dialog__header {
  text-align: center;
}
::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-radio__label {
  padding: 0 8px;
}
::v-deep .el-collapse-item__header {
  text-align: initial;
}
.custom_radios {
  text-align: center;
  margin-top: 30px;
}
.img_status {
  width: 30px;
  height: 30px;
}
.main_actions_buttons_wrapper {
  margin: 30px 0;
  .pre_buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    .custom_btn {
      background-color: #23ccef;
      border: 1px solid #23ccef;
      color: #fff;
      padding: 8px 20px;
      min-width: 120px;
      border-radius: 8px;
      &.is_disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
      &.examination {
        background-color: #fb404b;
        border-color: #fb404b;
      }
      &.correction {
        background-color: #87cb16;
        border-color: #87cb16;
      }
      &.delete {
        background-color: #ffa534;
        border-color: #ffa534;
      }
    }
  }
}
